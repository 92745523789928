import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import httpBuildQuery from 'http-build-query'

const urlAPI = process.env.VUE_APP_APIENDPOINT

export const InvoiceService = {
  getInvoices (filter, page, itemsPerPage) {
    filter.page = page
    filter.per_page = itemsPerPage

    let queryString = httpBuildQuery(filter)
    page = page || 1
    itemsPerPage = itemsPerPage || 5
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/customers?${queryString}`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getInvoice (idInvoice) {},
  postInvoice (data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/nfse`, data)
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getListTakersAvailable (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/pessoas?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getTaker (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/pessoas/${id}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  postGenerate (id, pessoa) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/invoice/${id}/generate`, { pessoa: pessoa }, { responseType: 'blob' })
          .then(function (res) {
            resolve({ data: res.data })
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
            reject(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  postUpdate (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/invoice/${id}/update`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  checkGenerationTime (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/invoice/${id}/generate/check`, {})
          .then(function (res) {
            resolve({ data: res.data })
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getReceiptFile (id, pessoa, pessoaId) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/invoice/${id}/generate/receipt`, { pessoa: pessoa, idPessoa: pessoaId }, { responseType: 'blob' })
          .then(function (res) {
            resolve({ data: res.data })
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
            reject(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}

export default InvoiceService
